import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, ModalBody, Row } from 'reactstrap'
import ButtonComponent from '../../../../@core/components/button/ButtonComponent'
import DropDown from '../../../../@core/components/DropDown/DropDown'
import leftArrow from "../../../../images/leftArrow.svg"
import { FaRegFolderClosed } from "react-icons/fa6";
import { X } from 'react-feather'
import "./NewCurriculam.css"

const ModalAssign = ({ isOpen, setIsOpen, title, category, part, links, handleSubmit, grade, contentData }) => {
    const [totalTopics, setTotalTopics] = useState(0)
    const [totalSubTopics, setTotalSubTopics] = useState(0)
    const [totalAtt, setTotalAtt] = useState(0)

    useEffect(() => {
        if (contentData) {
            let topicLength = 0
            let subTopicLength = 0
            let attachmentsLength = 0
            contentData.map(io => {
                if (io.topics) {
                    topicLength += io.topics.length
                    io.topics.map(elm => {
                        if (elm.sub_topics) {
                            subTopicLength += elm.sub_topics.length
                            if (elm.file_data) {
                                attachmentsLength += elm.file_data.length
                            }
                            elm.sub_topics.map(sub => {
                                if (sub.file_data) {
                                    attachmentsLength += sub.file_data.length
                                }
                            })
                        }
                    })
                }

            })
            setTotalTopics(topicLength)
            setTotalAtt(attachmentsLength)
            setTotalSubTopics(subTopicLength)
        }
    }, [contentData])
    return (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className="modal-lg" centered={true}>
            <div className='d-flex justify-content-between align-items-center p-4 flex-wrap'>
                <div className='d-flex justify-content-between align-items-center gap' >
                    <div className='d-flex justify-content-center align-items-center left-arrow-image' onClick={() => setIsOpen(!isOpen)}>
                        <img src={leftArrow} />
                    </div>
                    <div className='page-heading'>Curriculum Summary</div>
                </div>
                <div onClick={e => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
                    <X size={30} color='#C4C4C4' strokeWidth={3.5} />
                </div>
            </div>
            <ModalBody>
                <div className='curriculam-modal-section-part1 px-4'>
                    <h2 className='mb-2' style={{ color: ' #0258a1' }}>{title ? title : "Simple Machines"}</h2>
                    <p>Category : <span>{category ? category : "-"}</span></p>
                    <p>Grade : <span>{grade ? grade : "-"}</span></p>
                </div>
                <div className='p-4 mb-2'>
                    <div className=' curriculam-modal-section-part2 border rounded p-2 px-3'>
                        <p>Parts : <span>{part ? part : "0"}</span></p>
                        <p>Topics : <span>{totalTopics}</span></p>
                        <p>Sub Topics : <span>{totalSubTopics}</span></p>
                        <p>Attachments : <span>{totalAtt}</span></p>
                        {/* <p>Links : <span>{links ? links : "0"}</span></p> */}
                    </div>
                </div>
                <div className='d-flex justify-content-end px-4'>
                    <ButtonComponent buttonType={'secondary'} title={'Publish'} onClick={() => handleSubmit()} />
                </div>
            </ModalBody>

        </Modal>
    )
}

export default ModalAssign