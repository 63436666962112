import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import ButtonComponent from '../button/ButtonComponent';
import NoDataTableComponentSVGs from './NoDataTableComponentSVGs';

const NoDataTableComponent = ({
  heading = 'Requests',
  containerWidth = '397px',
  containerHeight = 'auto',
  image = 'default', // Key to map the image from JSON
  imgHeight = '48px',
  imgWidth = '48px',
  titleText = 'Nothing to See Here',
  subTitleText = 'Add Your Curriculum to Start Receiving School Requests!',
  btnShow = false,
  buttonTitle = 'Add Curriculum',
  onButtonClick
}) => {
  // Get the SVG from the mapping
  const svg = NoDataTableComponentSVGs[image] || NoDataTableComponentSVGs['default'];

  return (
    <Card>
      <CardBody>
        <p style={{ padding: '10px', fontWeight: '500' }}>{heading}</p>
        <div
          className="p-4 d-flex justify-content-center flex-column"
          style={{ margin: 'auto', width: containerWidth, height: containerHeight, color: '#8B8D97', gap: '10px' }}
        >
          <Row>
            <Col className="d-flex justify-content-center">
              {/* Render SVG based on its type */}
              {typeof svg === 'string' ? (
                <img src={svg} height={imgHeight} width={imgWidth} alt="noData" />
              ) : (
                <div style={{ height: imgHeight, width: imgWidth }}>{svg}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mt-1 d-flex justify-content-center">
              <p className="text-center EmptyData-heading">{titleText}</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <h6 className="text-center EmptyData-subheading">{subTitleText}</h6>
            </Col>
          </Row>
          {btnShow && <Row>
            <Col className="mt-1 d-flex justify-content-center">
              <ButtonComponent
                type="button"
                title={buttonTitle}
                buttonType="primary"
                onClick={onButtonClick}
              />
            </Col>
          </Row>}
        </div>
      </CardBody>
    </Card>
  );
};

export default NoDataTableComponent;
