import React from 'react';
import './CustomInput.css'

const CustomInput = ({ placeholder, className, value, onChange, required = false }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      className={className}
      value={value}
      onChange={onChange}
      required={required}
    />
  );
};

export default CustomInput;
