import React, { useState } from "react";
// import { ReactComponent as Delete } from "../../images/delete.svg";
import { Trash2 } from "react-feather";
import "./index.css";

const Delete = ({color = "#649DFF"}) => {
  return <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.564446 2.29102C0.252714 2.29102 0 2.54373 0 2.85546C0 3.16719 0.252714 3.41991 0.564446 3.41991V2.29102ZM16.3689 3.41991C16.6807 3.41991 16.9334 3.16719 16.9334 2.85546C16.9334 2.54373 16.6807 2.29102 16.3689 2.29102V3.41991ZM0.564446 3.41991H16.3689V2.29102H0.564446V3.41991Z" fill={color}/>
  <path d="M5.08008 2.85568C5.08008 3.16741 5.33279 3.42012 5.64452 3.42012C5.95626 3.42012 6.20897 3.16741 6.20897 2.85568H5.08008ZM10.7245 2.85568C10.7245 3.16741 10.9773 3.42012 11.289 3.42012C11.6007 3.42012 11.8534 3.16741 11.8534 2.85568H10.7245ZM10.1601 0.0334473H6.77342V1.16234H10.1601V0.0334473ZM6.77342 0.0334473C6.32431 0.0334473 5.89361 0.211852 5.57605 0.529415L6.37429 1.32766C6.48015 1.22181 6.62372 1.16234 6.77342 1.16234V0.0334473ZM5.57605 0.529415C5.25848 0.84698 5.08008 1.27768 5.08008 1.72679H6.20897C6.20897 1.57709 6.26844 1.43351 6.37429 1.32766L5.57605 0.529415ZM5.08008 1.72679V2.85568H6.20897V1.72679H5.08008ZM11.8534 2.85568V1.72679H10.7245V2.85568H11.8534ZM11.8534 1.72679C11.8534 1.27768 11.675 0.84698 11.3575 0.529415L10.5592 1.32766C10.6651 1.43351 10.7245 1.57709 10.7245 1.72679H11.8534ZM11.3575 0.529415C11.0399 0.211852 10.6092 0.0334473 10.1601 0.0334473V1.16234C10.3098 1.16234 10.4534 1.22181 10.5592 1.32766L11.3575 0.529415Z" fill={color}/>
  <path d="M6.20898 12.4513C6.20898 12.7631 6.4617 13.0158 6.77343 13.0158C7.08516 13.0158 7.33788 12.7631 7.33788 12.4513H6.20898ZM7.33788 6.80688C7.33788 6.49515 7.08516 6.24243 6.77343 6.24243C6.4617 6.24243 6.20898 6.49515 6.20898 6.80688H7.33788ZM7.33788 12.4513V6.80688H6.20898V12.4513H7.33788Z" fill={color}/>
  <path d="M9.59546 12.4513C9.59546 12.7631 9.84818 13.0158 10.1599 13.0158C10.4716 13.0158 10.7244 12.7631 10.7244 12.4513H9.59546ZM10.7244 6.80688C10.7244 6.49515 10.4716 6.24243 10.1599 6.24243C9.84818 6.24243 9.59546 6.49515 9.59546 6.80688H10.7244ZM10.7244 12.4513V6.80688H9.59546V12.4513H10.7244Z" fill={color}/>
  <path d="M2.82009 2.8086C2.79419 2.49794 2.52136 2.2671 2.2107 2.293C1.90004 2.3189 1.6692 2.59173 1.6951 2.90239L2.82009 2.8086ZM15.2379 2.90236C15.2638 2.5917 15.033 2.31888 14.7223 2.293C14.4116 2.26712 14.1388 2.49797 14.1129 2.80863L15.2379 2.90236ZM13.0706 15.3197C13.0588 15.4609 12.9945 15.5927 12.8903 15.6886L13.6548 16.5192C13.9674 16.2315 14.1605 15.8367 14.1956 15.4133L13.0706 15.3197ZM12.8903 15.6886C12.7861 15.7845 12.6496 15.8378 12.508 15.8378L12.5079 16.9666C12.9328 16.9666 13.3422 16.807 13.6548 16.5192L12.8903 15.6886ZM12.508 15.8378H4.42582V16.9666H12.5079L12.508 15.8378ZM4.42582 15.8378C4.28419 15.8378 4.14768 15.7845 4.04346 15.6886L3.27896 16.5192C3.59159 16.807 4.00092 16.9666 4.42582 16.9666V15.8378ZM4.04346 15.6886C3.93926 15.5927 3.87491 15.4611 3.8632 15.32L2.73818 15.4133C2.7733 15.8367 2.96633 16.2315 3.27896 16.5192L4.04346 15.6886ZM3.8632 15.32L2.82009 2.8086L1.6951 2.90239L2.73818 15.4133L3.8632 15.32ZM14.1129 2.80863L13.0706 15.3197L14.1956 15.4133L15.2379 2.90236L14.1129 2.80863Z" fill={color}/>
  </svg>
  
}

const ClearButton = ({ title = "Clear" }) => {
  const [color , setColor] = useState("#649DFF")

  return (
    <div className="clearBtn" onMouseEnter={() => setColor("white")} onMouseLeave={() => setColor("#649DFF")}>
      <div>{title}</div>
      <div>
        <Delete  color={color}/>
      </div>
    </div>
  );
};

export default ClearButton;
