import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  // Check if the user is authenticated
  const auth = useSelector(state => state.auth)
  const authentication_key = localStorage.getItem('authentication_key');
  // If authenticated, render the provided element (e.g., Dashboard)
  // If not authenticated, redirect to the login page
  return authentication_key ? element : <Navigate to="/" />;
};

export default ProtectedRoute;