import React, { useState, useEffect } from "react";
// import { useMediaQuery } from 'react-responsive'
import "./index.css";

const SwitchTabBtn = ({
  numberOfButton,
  switchTabBtnClick,
  mediaQuery,
  isActive,
  activeIndex,
}) => {
  const [activeBtn, setActiveBtn] = useState(0);
  // const isBigScreen = useMediaQuery({ query: `(max-width: ${mediaQuery})` })

  useEffect(() => {
    if (numberOfButton.length > 0) {
      const parent_container_btn_tab = document.getElementsByClassName(
        "parent-container-btn-tab".concat(numberOfButton[0].value)
      )[0];
      // if (isBigScreen) {
      //     if (parent_container_btn_tab) {
      //         parent_container_btn_tab.style.width = "auto"
      //         parent_container_btn_tab.style.overflow = "auto"
      //         parent_container_btn_tab.style.whiteSpace = "nowrap"
      //     }
      // } else {
      //     if (parent_container_btn_tab) {
      //         parent_container_btn_tab.style.width = "fit-content"
      //     }
      // }
      if (isActive) {
        setActiveBtn(activeIndex);
      }
    }
  }, [numberOfButton]);

  return (
    <>
      {numberOfButton.length > 0 && (
        <>
          <div
            className={`parent-container-btn-tab ${"parent-container-btn-tab".concat(
              numberOfButton[0].value
            )}`}
          >
            {numberOfButton.map((btn, ind) => {
              return (
                <>
                  <div
                    style={{
                      backgroundColor: ind === activeBtn && "var(--primary)",
                      color: ind === activeBtn ? "white" : "#C4C4C4",
                      borderTopLeftRadius: ind === 0 ? "7px" : "0px",
                      borderBottomLeftRadius: ind === 0 ? "7px" : "0px",
                      borderTopRightRadius:
                        ind + 1 === numberOfButton.length ? "7px" : "0px",
                      borderBottomRightRadius:
                        ind + 1 === numberOfButton.length ? "7px" : "0px",
                      borderRight:
                        ind + 1 !== numberOfButton.length &&
                        "1px solid lightgray",
                    }}
                    className="child-one-btn-tab"
                    onClick={() => {
                      switchTabBtnClick({
                        button_name: btn.value,
                        index: ind,
                      });
                      setActiveBtn(ind);
                    }}
                  >
                    <div
                      className={`child-two-btn-tab ${btn.className} ${
                        ind !== activeBtn
                          ? "child-two-btn-tab-condition"
                          : "child-two-btn-tab-condition1"
                      }`}
                    >
                      {btn.label}
                    </div>
                    {btn.count && (
                      <div
                        style={{
                          backgroundColor:
                            ind === activeBtn ? "white" : "#EBF2FF",
                        }}
                        className="total-count"
                      >
                        <div>{btn.count}</div>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

SwitchTabBtn.defaultProps = {
  numberOfButton: [],
  mediaQuery: "0px",
  isActive: false,
  activeIndex: 0,
};

export default SwitchTabBtn;
