import React, {useState, useEffect} from 'react'
import { Card, Row, Col, CardBody } from 'react-bootstrap'
import file from "../../../../../images/file.svg"
import './activity.css'
import ButtonComponent from '../../../../../@core/components/button/ButtonComponent'
import { InfinitySpin } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import ActivitySVG from "../Images/ActivitySVG.svg"
import NoDataTableComponent from '../../../../../@core/components/NoDataTableComponent/NoDataTableComponent'
const SpinnerComponent = () => {
  return (<div className='spinner-component'>
    <InfinitySpin
      visible={true}
      width="200"
      color="#0258a1"
      ariaLabel="infinity-spin-loading"
    />
  </div>)
}

const Activity = () => {
  const [load, setLoad] = useState(false)
  const userName = localStorage.getItem("user_name")
  const baseUrl = process.env.REACT_APP_API_URL
  const authentication_key = localStorage.getItem('authentication_key');
  const headers = {
      'Authentication_key': authentication_key,
      'Authorization': 'Bearer ' + authentication_key
  };
  const navigate = useNavigate()
  const [activityData, setActivityData] = useState([])

  const handleGetActivityData = () => {
    setLoad(true)
    axios.get(`${baseUrl}/partner/curriculum-dashboard-activity`, {
        headers
    }).then(res => {
        const response = res.data
        if (response.status === "success") {
            const data = response.data
            if (data.length > 0) {
                setActivityData(data)
            }
            setLoad(false)
        } else {
            console.log(response.message)
            setLoad(false)
        }
    }
    ).catch(err => {
        console.log(err)
        setLoad(false)
    })

}
useEffect(() => {
  handleGetActivityData()
}, [])
  const schoolDetailsSubject = [
    {
      name: "English Compositions",
      time: "4 Min Before"
    },
    {
      name: "Statistics",
      time: "2 Days Before"
    },
    {
      name: "Hindi Poems",
      time: "4 Months Before"
    },
    {
      name: "EVS Mock Papers",
      time: "1 Yr Before"
    },

  ]

  return (<>
    {activityData.length > 0 ? (<Card className='mt-4 p-4'>
      <p>Activity</p>
      {load ? (<div className='d-flex align-items-center justify-content-center'>
        <SpinnerComponent />
      </div>) : <div className='home-recent-div mt-4'>
        {
           activityData && activityData.length > 0 && activityData.map((element, idx) => {
            return (
              <div className='d-flex align-items-center flex-row  align-content-center gap mb-4' >
                <div className='home-recent-div-image' >
                  <img src={file} />
                </div>
                <div className='subject-details-recent-section'>
                  <h2>{element.activity ? element.activity : ""}</h2>
                  <p>Last Updated: <span>{element.time ? element.time : ""}</span></p>
                </div>
                {/* <div className='d-flex flex-fill justify-content-end'>
                  <ButtonComponent onClick={() => navigate("/modules/curriculum/addcurriculum", { state: { id: element.id, isNotAside: true } })} buttonType={'primary'} title={'Edit'} />
                </div> */}
              </div>
            )
          })

        }
      </div>}
    </Card>) : <div className='mt-4'>
    <NoDataTableComponent
            heading="Activity"
            image="activity_svg" // Pass the key to select the correct SVG
            titleText="Nothing to See Here"
            subTitleText="You currently have no requests from school."
            buttonTitle="Add Curriculum"
            containerHeight="355px"
            // onButtonClick={() => alert('Add Exam clicked!')}
        />
      </div>}
    </>)
}

export default Activity