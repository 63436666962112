import React, { useContext, useEffect, useState } from 'react'
import ButtonComponent from '../../../../@core/components/button/ButtonComponent'
import TableOptions from '../../../../@core/components/table-options'
import Datatable from '../../../../@core/components/DataTable/DataTable'
import { Eye, Edit, Trash, CheckSquare } from 'react-feather'
import { useNavigate, useLocation } from 'react-router-dom'
import { Card } from 'reactstrap'
import Toggle from 'react-toggle'
import leftArrow from "../../../../images/leftArrow.svg"
import { handleMangeCrudRight } from '../../../../Utility/Utils'
import moment from 'moment'
import { InfinitySpin } from 'react-loader-spinner'
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import { UserContext } from '../../../../context/UserContext'
const SpinnerComponent = () => {
  return (<div className='spinner-component'>
    <InfinitySpin
      visible={true}
      width="200"
      color="#0258a1"
      ariaLabel="infinity-spin-loading"
    />
  </div>)
}
const ListCurriculums = () => {
  const { getUserData } = useContext(UserContext);
  const parentId = "3"
  const moduleId = "4"
  const userType = getUserData.userType
  const crud_rights = getUserData.user_data?.crud_rights
  const [load, setLoad] = useState(false)
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_API_URL
  const authentication_key = localStorage.getItem('authentication_key');
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true)
  const [curriculamState, setCurriculamState] = useState('Curriculam')
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [tab, setTab] = useState("curriculums");
  const [optionRights, setOptionRights] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const headers = {
    'Authentication_key': authentication_key, // Include any authorization token if needed
    'Authorization': 'Bearer ' + authentication_key,
    // Add other headers as needed
  };
  useEffect(() => {
    if (crud_rights && crud_rights.length > 0) {
      const settingRight = JSON.parse(crud_rights)[parentId][moduleId]
      // console.log("settingRight", settingRight)
      if (settingRight && settingRight.length > 0) {
        setOptionRights(settingRight)
      }
    }
    // optionRights ["0","4"]
  }, [crud_rights])
  console.log(optionRights)
  const handleGetTableData = (id) => {
    setLoad(true)
    const payload = {
      id,
      page: currentPage,
      perPage: rowsPerPage,
      auth_bearer_token: authentication_key
    }
    axios.get(`${baseUrl}/partner/curriculum-bundle-show`, {
      headers,
      params: payload
    }).then(res => {
      const response = res.data
      if (response.status === "success") {
        const data = response.data
        setTableData(data)
        setTotalPage(response.total_pages)
        setLoad(false)
      } else {
        console.log(response.message)
        setLoad(false)
      }
    }
    ).catch(err => {
      console.log(err)
      setLoad(false)
    })

  }

  useEffect(() => {
    const state = location.state
    console.log(state)
    if (state && state.id) {
      handleGetTableData(state.id)
    }
  }, [flag])
  const handleChangeCurriculumTypeStatus = (id) => {
    axios.postForm(`${baseUrl}/partner/curriculum-type-change-status`, {
      id,
      auth_bearer_token: authentication_key
    }, {
      headers: {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
        'authentication_key': authentication_key, // Include any authorization token if needed
        'Authorization': 'Bearer ' + authentication_key,
        // Add other headers as needed
      }
    }).then(res => {
      const data = res.data
      if (data.status === "success") {
        setFlag(!flag)
        handleGetTableData(id)
        toast.success(data.message)

      } else {
        toast.error(data.message)
      }
    }
    ).catch(err => {
      console.log(err)
    })
  }
  const handlePerPage = (e) => {
    setRowsPerPage(Number(e.target.value))
  }
  const handlePagination = (page) => {
    setCurrentPage(page.selected)
  }
  const columns = [
    {
      name: "Curriculum",
      cell: (row) => (<div style={{ cursor: "pointer" }} onClick={() => navigate("/modules/curriculum/view", { state: { id: row.id, isNotAside: true } })}>{row.title ? row.title : "-"}</div>)
    },
    {
      name: "Curriculum Type",
      center: true,
      cell: (row, i) => {

        return (<>
          <div className='toggle-div-style' style={{ fontSize: "12px" }}>
            <Toggle
              icons={{
                checked: "Public",
                unchecked: "Private"
              }}
              onClick={() => handleChangeCurriculumTypeStatus(row.id)}
              checked={row.curriculum_type === "1"}
            />
          </div>
        </>)
      }
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<>
        <div
          className={`curriculum-assign-status rounded-pill ${row.status === "2"
            ? "status-rejected-color"
            : row.status === "3"
              ? "status-pending-color"
              : row.status === "1"
                ? "status-assign-color"
                : ""
            }`}
        >
          {row.status === "2"
            ? "Unpublished"
            : row.status === "3"
              ? "Draft"
              : row.status === "1"
                ? "Publish"
                : ""}
        </div>
      </>)
    },
    {
      name: "Created At",
      center: true,
      cell: (row) => (<div onClick={() => navigate("/modules/curriculum/view", { state: { id: row.id, isNotAside: true } })}>{moment(row.created_at * 1000).format("Do MMM, YYYY")}</div>)
    },
    {
      name: "Option",
      center: true,
      cell: row => {

        const tableMenuType = [
          {
            value: "optionDropdown",
            isActive: false
          },
          {
            value: "optionIcon",
            isActive: true
          }
        ]

        const tableOptionArray = [
          {
            name: "View",
            value: "view",
            icon: <Eye
              size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
              className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
            />,
            className: "",
            isActive: true
          },
          handleMangeCrudRight({ userType, options: optionRights, option_id: "3" }) && {
            name: "Edit",
            value: "edit",
            icon: <Edit
              size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
              className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
            />,
            className: "",
            isActive: true
          },

          handleMangeCrudRight({ userType, options: optionRights, option_id: "5" }) && {
            name: "Delete",
            value: "delete",
            icon: <Trash
              size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
              className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
            />,
            className: "",
            isActive: true
          }
          // {
          //   name: `Mask as ${row.status === "1" ? "Unpublish" : "Publish"}`,
          //   value: "markAs",
          //   icon: <CheckSquare
          //     size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
          //     className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
          //   />,
          //   className: "",
          //   isActive: true
          // }
        ]
        const handleDelete = (id) => {
          axios.postForm(`${baseUrl}/partner/curriculum-destroy`, {
            id,
            auth_bearer_token: authentication_key
          }, {
            headers: {
              'Content-Type': 'application/json', // Set the content type based on your API requirements
              'authentication_key': authentication_key, // Include any authorization token if needed
              'Authorization': 'Bearer ' + authentication_key,
              // Add other headers as needed
            }
          }).then(res => {
            const data = res.data
            if (data.status === "success") {
              setFlag(!flag)
              toast.success(data.message)

            } else {
              toast.error(data.message)
            }
          }
          ).catch(err => {
            console.log(err)
          })
        }
        const handleDeleteData = (row) => {
          confirmAlert({
            title: "Confirm delete",
            message: 'Are you sure to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  handleDelete(row.id)
                },
              },
              {
                label: 'No',
                onClick: () => {
                  // Handle the action when 'No' is clicked
                  // console.log('You clicked No!');
                },
              },
            ],
            // Custom styles for the confirm pop-up
            overlayClassName: 'custom-overlay-class',
            className: 'custom-modal-class',
          });
        }
        const handleTableOption = (e) => {
          if (e.value === "delete") {
            handleDeleteData(row)
          } else if (e.value === "view") {
            navigate("/modules/curriculum/view", { state: { id: row.id, isNotAside: true } })
          } else if (e.value === "edit") {
            navigate("/modules/curriculum/addcurriculum", { state: { id: row.id, isNotAside: true } })
          } else if (e.value === "markAs") {
            handleChangeStatus(row.id)
          }
        }

        return (<>
          {/* <div className="ml-2 view-table-option-tour"> */}
          <TableOptions
            tableOptionArray={tableOptionArray.filter(io => io.isActive === true)}
            handleTableOption={handleTableOption}
            optionType={tableMenuType.filter(io => io.isActive)[0].value}
          />
          {/* </div> */}

        </>)
      }
    }
  ]
  return (
    <div>
      <div className='mb-3'>
        <Card  >
          <div className='d-flex align-items-center p-3 flex-wrap gap-4'>
            <div onClick={e => navigate(-1)} style={{ cursor: "pointer" }} >
              <img src={leftArrow} />
            </div>
            <div className='d-flex justify-content-between align-items-center gap' >
              {/* <div className='d-flex justify-content-center align-items-center left-arrow-image'>
                <img src={leftArrow} />
              </div> */}
              <div className='page-heading'>Curriculum</div>
            </div>

          </div>
        </Card>

        <Card className='overflow-auto curriculum-list p-4 mt-4'>
          {load ? (<>
            <SpinnerComponent />
          </>) : <Datatable
            columns={columns}
            currentPage={currentPage}
            tableData={tableData}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            isSearchable={false}
            paginate={true}
            handlePerPage={handlePerPage}
            handlePagination={handlePagination}
            noHeader={true}
          />}
        </Card>
      </div>
    </div>
  )
}

export default ListCurriculums