import React, { useEffect, useState } from 'react'
import { Card, Row, Col, CardBody } from 'react-bootstrap'
import left from "../../../../../images/left.svg"
import right from "../../../../../images/right.svg"
import demo from "../../../../../images/demo.svg"
import cross from "../../../../../images/+.svg"
import ButtonComponent from '../../../../../@core/components/button/ButtonComponent'
import "./schoolRequest.css"
import axios from 'axios'
import { InfinitySpin } from "react-loader-spinner";
import moment from "moment";
import { X } from 'react-feather'
import loadable from "react-loadable";
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import DemoRequestSVG from '../Images/DemoRequest.svg'
import NoDataTableComponent from '../../../../../@core/components/NoDataTableComponent/NoDataTableComponent'
const ApprovedPopUp = loadable({
    loader: () => import(/* webpackChunkName: 'School' */ "./ApprovedPopUp/index"),
    loading: () => <></>,
});

const SpinnerComponent = () => {
    return (
        <div className="spinner-component">
            <InfinitySpin
                visible={true}
                width="200"
                color="#0258a1"
                ariaLabel="infinity-spin-loading"
            />
        </div>
    );
};

const SchoolRequest = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const authentication_key = localStorage.getItem("authentication_key");
    const [load, setLoad] = useState(false);
    const [flag, setFlag] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalPage, setTotalPage] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [openApproveModal, setOpenAproveModal] = useState(false);
    const [editRow, setEditRow] = useState("");
    const headers = {
        Authentication_key: authentication_key,
        'Authorization': 'Bearer ' + authentication_key
    };
    const handleRejected = (id) => {
        axios
            .postForm(
                `${baseUrl}/partner/curriculum-integrate-reject-status`,
                {
                    id,
                    auth_bearer_token: authentication_key,
                },
                {
                    headers: {
                        "Content-Type": "application/json", // Set the content type based on your API requirements
                        authentication_key: authentication_key, // Include any authorization token if needed
                        'Authorization': 'Bearer ' + authentication_key
                        // Add other headers as needed
                    },
                }
            )
            .then((res) => {
                const data = res.data;
                if (data.status === "success") {
                    setFlag(!flag);
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleReject = (school) => {
        confirmAlert({
            title: "Confirm reject",
            message: "Are you sure to reject?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        handleRejected(school.id);
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        // Handle the action when 'No' is clicked
                        // console.log('You clicked No!');
                    },
                },
            ],
            // Custom styles for the confirm pop-up
            overlayClassName: "custom-overlay-class",
            className: "custom-modal-class",
        });
    };
    const handleApproved = (id) => {
        axios
            .postForm(
                `${baseUrl}/partner/curriculum-integrate-accept-status`,
                {
                    id,
                    auth_bearer_token: authentication_key,
                },
                {
                    headers: {
                        "Content-Type": "application/json", // Set the content type based on your API requirements
                        authentication_key: authentication_key, // Include any authorization token if needed
                        'Authorization': 'Bearer ' + authentication_key,
                        // Add other headers as needed
                    },
                }
            )
            .then((res) => {
                const data = res.data;
                if (data.status === "success") {
                    setFlag(!flag);
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleGetTableData = () => {
        setLoad(true);
        const payload = {
            page: currentPage,
            perPage: rowsPerPage,
            auth_bearer_token: authentication_key,
        };
        axios
            .get(`${baseUrl}/partner/curriculum-integrate-table-data`, {
                headers,
                params: payload,
            })
            .then((res) => {
                const response = res.data;
                if (response.status === "success") {
                    const data = response.data;
                    setTableData(data);
                    setTotalPage(response.total_pages);
                    setLoad(false);
                } else {
                    console.log(response.message);
                    setLoad(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoad(false);
            });
    };

    const schools = [
        {
            icon: demo,
            name: 'Maplewood Academy',
            curriculam_name: ' History Dynamics',

        },

        {
            icon: demo,
            name: 'Crestview High School',
            curriculam_name: 'Archelogy'
        }
    ]
    const handlePagination = (type) => {
        if (type === "inc") {
            if (totalPage < currentPage) {
                setCurrentPage(currentPage + 1)
            }
        }
        if (type === "dec") {
            if (currentPage > 0) {
                setCurrentPage(currentPage - 1)
            }
        }
    }

    useEffect(() => {
        handleGetTableData()
    }, [currentPage, flag])
    return (<>
        {tableData.length > 0 ? (<Card className='p-4 overflow-auto' style={{
            border: 'none', boxShadow: '0px 4px 20px 0px #001E521A', borderRadius: '20px'
        }}>
            <div className='d-flex justify-content-between flex-wrap school-request-section'>
                <span>Integration Requests</span>
                <div className='d-flex gap'>
                    <img src={left} onClick={() => handlePagination("dec")} />
                    <img src={right} onClick={() => handlePagination("inc")} />
                </div>
            </div>
            {
                load ? (<>
                    <div className='d-flex justify-content-center align-items-center'>
                        <SpinnerComponent />
                    </div>
                </>) : (<>
                    <div className='mt-4 d-flex flex-column '>
                        {
                            tableData && tableData.map((school, idx) => {
                                return (
                                    <div className='d-flex justify-content-between  flex-wrap   align-items-center'>
                                        <div className='d-flex align-items-center gap  mb-4' >
                                            {school.icon && <div>
                                                <img src={school.icon} />
                                            </div>}
                                            <div className='school-name'>
                                                <h2>{school.franchiser_name}</h2>
                                                <p style={{ color: '#838383' }}>Category:<span style={{ color: "#000000" }}> {school.category_name} </span></p>
                                            </div>
                                        </div>
                                        <div className='d-flex gap approved-container-or-reject align-items-center  mb-4'>
                                            <button
                                                onClick={() => {
                                                    if (school.status !== "1") {
                                                        setEditRow(school);
                                                        setOpenAproveModal(true);
                                                    }
                                                }}
                                                disabled={school.status !== "1" ? false : true}
                                                className={`btn ${school.status === "2"
                                                    ? "home-status-rejected-color"
                                                    : school.status === "3"
                                                        ? "home-status-pending-color"
                                                        : school.status === "1"
                                                            ? "home-status-assign-color"
                                                            : "home-status-pending-color"
                                                    }`}
                                            >
                                                {school.status === "2" ? "Rejected" : school.status === "3" ? "Pending" : "Approved"}
                                            </button>
                                            {/* <ButtonComponent buttonType={"primary"} title={"Approve"} /> */}
                                            {/* <X
                                                onClick={() => {
                                                    school.status === "3" && handleRejected(school.id);
                                                }}
                                                size={20}
                                                color="#C4C4C4"
                                                strokeWidth={3.5}
                                            /> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>)
            }
            {openApproveModal && (
                <ApprovedPopUp
                    isOpen={openApproveModal}
                    setIsOpen={setOpenAproveModal}
                    setCurriculumData={setEditRow}
                    curriculumData={editRow}
                    handleApprove={handleApproved}
                    handleReject={handleReject}
                />
            )}
        </Card>) : <NoDataTableComponent
            heading="Integration Request"
            image="demoRequests_svg" // Pass the key to select the correct SVG
            titleText="Nothing to See Here"
            subTitleText="You currently have no requests from school."
            buttonTitle="Add Curriculum"
            id = "integrationRequest"
            // onButtonClick={() => alert('Add Exam clicked!')}
        />}
    </>)
}

export default SchoolRequest