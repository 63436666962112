import React, { useEffect, useState } from 'react';
import Sidebar from '../../../@core/components/common/Sidebar';
import Router from '../../../router/Router';
import ActionBar from '../../../@core/components/ActionBar';
import { useLocation } from 'react-router-dom';
import './DashBoard.css';

const DashBoard = () => {
  const [isAsideOpen, setAsideOpen] = useState(() => {
    // Retrieve the initial value from localStorage
    const savedAsideState = localStorage.getItem('isAsideOpen');
    return savedAsideState ? JSON.parse(savedAsideState) : false;
  });

  const [showAside, setShowAside] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const state = location.state;
    if (state && state.isNotAside) {
      setShowAside(false);
    } else {
      setShowAside(true);
    }
  }, [location]);

  return (
    <div className='d-flex dashboard-section'>
      {showAside && <Sidebar isAsideOpen={isAsideOpen} setAsideOpen={setAsideOpen} />}
      <div className='py-4 w-100 px-2'>
        <ActionBar isAsideOpen={isAsideOpen} setAsideOpen={setAsideOpen} />
        <Router />
      </div>
    </div>
  );
};

export default DashBoard;
