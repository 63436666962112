import loadable from 'react-loadable';
import ListCurriculums from '../../pages/modules/Curriculam/ListCurriculums';

const Home = loadable({
  loader: () => import(/* webpackChunkName: 'Home' */'../../pages/modules/DashBoard/Home/Home'),
  loading: () => (<></>)
})

const Schools = loadable({
  loader: () => import(/* webpackChunkName: 'Schools' */'../../pages/modules/Schools'),
  loading: () => (<></>)
})

const Subscriptions = loadable({
  loader: () => import(/* webpackChunkName: 'Subscriptions' */'../../pages/modules/Subscriptions/Subscriptions'),
  loading: () => (<></>)
})

const Curriculam = loadable({
  loader: () => import(/* webpackChunkName: 'Curriculam' */'../../pages/modules/Curriculam/Curriculam'),
  loading: () => (<></>)
})

const AddCurriculum = loadable({
  loader: () => import(/* webpackChunkName: 'AddCurriculum' */'../../pages/modules/Curriculam/NewCurriculam/NewCurriculam'),
  loading: () => (<></>)
})

const ViewCurriculum = loadable({
  loader: () => import(/* webpackChunkName: 'ViewCurriculum' */'../../pages/modules/Curriculam/ViewCurriculum'),
  loading: () => (<></>)
})

const Account = loadable({
  loader: () => import(/* webpackChunkName: 'Account' */'../../pages/modules/Account'),
  loading: () => (<></>)
})

// const Storage = loadable({
//   loader: () => import(/* webpackChunkName: 'Admin' */'../../pages/modules/Admin/Storage'),
//   loading: () => (<></>)
// })

const Settings = loadable({
  loader: () => import(/* webpackChunkName: 'Settings' */'../../pages/modules/Admin/Settings'),
  loading: () => (<></>)
})
const CurriculamSettings = loadable({
  loader: () => import(/* webpackChunkName: 'CurriculamSettings' */'../../pages/modules/Admin/Settings/Curriculum/index'),
  loading: () => (<></>)
})
const Staff = loadable({
  loader: () => import(/* webpackChunkName: 'Staff' */'../../pages/modules/Staff'),
  loading: () => (<></>)
})

const AppRoutes = [
  {
    path: "/home",
    Component: Home
  },
  {
    path: "/schools",
    Component: Schools
  },
  {
    path: "/subscriptions",
    Component: Subscriptions
  },
  {
    path: "/curriculum",
    Component: Curriculam
  },
  {
    path: "/curriculum/addcurriculum",
    Component: AddCurriculum
  },
  {
    path: "/curriculum/list",
    Component: ListCurriculums
  },
  {
    path: "/curriculum/view",
    Component: ViewCurriculum
  },
  {
    path: "/account",
    Component: Account
  },
  // {
  //   path: "/storage",
  //   Component: Storage
  // },
  {
    path: "/settings/storage",
    Component: Settings
  },
  {
    path: "/settings/curriculum",
    Component: CurriculamSettings
  },
  {
    path: "/staff",
    Component: Staff
  }
]

export default AppRoutes