import React, { useState } from 'react'
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import "./index.css"

const CustomInputWithIncDec = ({ title, onChange, value, placeholder = "", isPlusMinus = true, onClickAdd, onClickMinus, className, style }) => {
    const [isInputFocused, setIsInputFocused] = useState(false)
    const [btnFocus, setBtnFocus] = useState("")
    return (<>
        <div style={style} className={`custom-input-inc-dec ${className}`}>
            <div className='custom-input-title default-text-color'>{title}</div>
            {isPlusMinus && <AiFillMinusCircle onClick={onClickMinus} className="plus-minus-icons"  size={21} style={{cursor:"pointer"}}/>}
            <input
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => { setIsInputFocused(false); setBtnFocus("") }}
            />
            {isPlusMinus && <AiFillPlusCircle onClick={onClickAdd} className="plus-minus-icons" size={21} style={{cursor:"pointer"}}/>}
        </div>
    </>)
}

export default CustomInputWithIncDec