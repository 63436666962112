import React from 'react'
import { FaCheck } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import "./index.css"

const SelectedButton = ({ title = "Demo", size, buttonType = "secondary", onClick }) => {
    return (<>
        {buttonType === "secondary" || buttonType === "primary-checked" ? (<>
            <div className={`custom-select-btn ${buttonType === "primary-checked" ? "active" : ""} rounded-pill`} onClick={onClick}>
                {/* <FaRegCheckCircle /> */}
                {buttonType === "primary-checked" ? <FaRegCheckCircle size={size} /> : <FaCheck size={size} />}
                <div className='px-2' style={{ fontSize: `${size}px` }}>{title}</div>
            </div>
        </>) : buttonType === "primary" ? (<>
            <div className='custom-select-btn-primary rounded-pill' onClick={onClick}>
                <FaRegCheckCircle size={size ? size : 18} />
                {/* <FaCheck size={size} /> */}
                <div className='px-2' style={{ fontSize: `${size}px` }}>{title}</div>
            </div>
        </>) : (<>
            <div className='custom-select-btn-secondary  rounded-pill' onClick={onClick}>
                {/* <FaRegCheckCircle /> */}
                <FaCheck  />
                <div className='px-2'>{title}</div>
            </div>
        </>)
        }
    </>)
}

export default SelectedButton