// import { ButtonComponent } from "./components/button/ButtonComponent";
// import Router from "./router/Router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from "react";
import Login from "./pages/auth/Login"
import Home from "./pages/modules/DashBoard/Home/Home"
import Dashboard from "./pages/modules/DashBoard/DashBoard"
import ProtectedRoute from './router/ProtectedRoute';
import { UserProvider } from './context/UserContext';
import "./index.css"
function App() {
  const authentication_key = localStorage.getItem('authentication_key');
  
  return (<>
  <UserProvider>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/modules/*" element={<ProtectedRoute element={<Dashboard />} />} />
    </Routes>
    <ToastContainer position="top-center" />
    </UserProvider>
  </>);
}

export default App;
