import React from "react";
import { Card, CardBody } from "react-bootstrap";
import { Menu, LogOut } from "react-feather";
import { useNavigate } from "react-router-dom";
function ActionBar({isAsideOpen, setAsideOpen}) {
  const navigator = useNavigate();
  const handleLogOut = () => {
    localStorage.clear();
    navigator("/");
  };
  return (
    <>
      <Card className={`mb-4 d-xl-none`}>
        <CardBody>
          <div className="d-flex justify-content-between p-2">
            <Menu color="#0258a1" onClick={() => setAsideOpen(!isAsideOpen)}/>
            <LogOut color="#0258a1" onClick={() => handleLogOut()} />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default ActionBar;
