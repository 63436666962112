import React, { forwardRef } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "./TextEditor.css"
 
const TextEditor = forwardRef(
  ({ modules, value, onChange, placeholder, height, className, readOnly }, ref) => {
    return (
      <ReactQuill
        ref={ref}
        value={value}
        onChange={onChange}
        modules={modules || TextEditor.modules}
        formats={TextEditor.formats}
        className={className}
        readOnly={readOnly}
        theme="snow"
        style={{ height: height || 300, color: "black" }}
        placeholder={placeholder}
      />
    )
  }
)
 
// Configurations for the editor
TextEditor.modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["direction", { align: [] }],
    ["link"]
  ]
}
 
TextEditor.formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "header",
  "blockquote",
  "code-block",
  "list",
  "bullet",
  "indent",
  "direction",
  "align",
  "link"
]
 
export default TextEditor
 