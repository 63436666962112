import React, { useState } from "react";
import "./index.css";
import ButtonComponent from "../../../@core/components/button/ButtonComponent";
import { Card } from "react-bootstrap";
import SwitchTabBtn from "../../../@core/components/SwitchTabBtn";
import loadable from "react-loadable";

const Curriculums = loadable({
    loader: () => import(/* webpackChunkName: 'School' */ "./Curriculums"),
    loading: () => <></>,
  });

const Requests = loadable({
    loader: () => import(/* webpackChunkName: 'School' */ "./Requests/index"),
    loading: () => <></>,
  });

const School = () => {
  const [tab, setTab] = useState("requests");
  const [activeIndex, setActiveIndex] = useState(0);

  const btnNameArray = [
    {
      label: "Requests",
      value: "requests",
      isActive: true,
    },
    {
      label: "Curriculums",
      value: "curriculums",
      isActive: true,
    },
  ];

  const switchTabBtnClick = (btnName) => {
    let  {button_name, index} = btnName
    setTab(button_name)
    setActiveIndex(index)
    // redirectSwitchTab({ switchTabArray: btnNameArray.filter(io => io.isActive === true), value: btnName.button_name, setActiveIndex, switchTab: setTab })
  };

  return (
    <>
      <div className="mb-3">
        <Card>
          <div className="d-flex justify-content-between align-items-center p-4 flex-wrap">
            <div className="d-flex justify-content-between align-items-center gap">
              {/* <div className='d-flex justify-content-center align-items-center left-arrow-image' onClick={e => setIsActive(false)} >
                <img src={leftArrow} />
              </div> */}
              <div className="page-heading">Schools</div>
            </div>
            {/* <div className='d-flex justify-content-center align-items-center gap'>
              <ButtonComponent buttonType={'secondary'} title={'Take A Tour'} />
              <ButtonComponent buttonType={'primary'} title={'Add New'} onClick={e => setAssignModule(true)} />
            </div> */}
          </div>
        </Card>
      </div>
      <SwitchTabBtn
        numberOfButton={btnNameArray.filter((io) => io.isActive === true)}
        switchTabBtnClick={switchTabBtnClick}
        mediaQuery={"415px"}
        isActive={true}
        activeIndex={activeIndex}
      />
      <div className="mt-3">
         {
        tab === "requests" ? (<>
        <Requests/>
        </>) : tab === "curriculums" && (<>
        <Curriculums/>
        </>)
      }
      </div>
     
    </>
  );
};

export default School;
