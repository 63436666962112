import React from 'react'
import { toast } from 'react-toastify'

const ApiResponse = (api_response, custom_msg) => {
    if (custom_msg) {
        toast.error(custom_msg)
    } else {
        if (api_response.status) {
            toast.error(api_response.message)
        }
    }

    return (<></>)
}

export default ApiResponse