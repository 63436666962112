import React, { useEffect, useRef } from 'react';
import Vimeo from '@vimeo/player';
import "./index.css"

const VimeoPlayer = ({ videoId }) => {
    const playerRef = useRef(null);

    useEffect(() => {
      const player = new Vimeo(playerRef.current, {
        id: videoId,
        autoplay: true // You can set autoplay to false if you don't want the video to autoplay
      });
  
      return () => {
        player.destroy();
      };
    }, [videoId]);
  
    return <div className='vimeo-vedio-player-container' ref={playerRef} />;
};

export default VimeoPlayer;
