import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'react-bootstrap'
import ButtonComponent from '../../../@core/components/button/ButtonComponent'
import Datatable from '../../../@core/components/DataTable/DataTable'
import TableOptions from "../../../@core/components/table-options"
import SwitchTabBtn from "../../../@core/components/SwitchTabBtn"
import loadable from "react-loadable"
import { Eye } from 'react-feather'
import "./curriculam.css"
import { confirmAlert } from 'react-confirm-alert'
import { toast } from "react-toastify"
import { UserContext } from '../../../context/UserContext'
import { handleMangeCrudRight } from '../../../Utility/Utils'
import axios from 'axios'
import NewCurriculam from './NewCurriculam/NewCurriculam'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import { InfinitySpin } from 'react-loader-spinner'
import FolderIcon from "./folderIcon.svg"

// import RemarksTable from './Evaluation/Remark/Remark'
// import Skill from './Evaluation/Skill/Skill'

const SpinnerComponent = () => {
  return (<div className='spinner-component'>
    <InfinitySpin
      visible={true}
      width="200"
      color="#0258a1"
      ariaLabel="infinity-spin-loading"
    />
  </div>)
}
const RemarksTable = loadable({
  loader: () => import(/* webpackChunkName: 'School' */ "./Evaluation/Remark/Remark"),
  loading: () => <></>,
});

const Skill = loadable({
  loader: () => import(/* webpackChunkName: 'Skill' */ "./Evaluation/Skill/Skill"),
  loading: () => <></>,
});
const Category = loadable({
  loader: () => import(/* webpackChunkName: 'Category' */ "./Category/index"),
  loading: () => <></>,
});
const Curriculam = () => {
  const { getUserData } = useContext(UserContext);
  const parentId = "3"
  const moduleId = "4"
  const userType = getUserData.userType
  const crud_rights = getUserData.user_data?.crud_rights
  const baseUrl = process.env.REACT_APP_API_URL
  const authentication_key = localStorage.getItem('authentication_key');
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true)
  const [curriculamState, setCurriculamState] = useState('Curriculam')
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  const [dataShowType, setDataShowType] = useState("grid");
  const [tableData, setTableData] = useState([])
  const [load, setLoad] = useState(false)
  const [tab, setTab] = useState("curriculums");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [optionRights, setOptionRights] = useState([])
  // const btnNameArray = [
  //   {
  //     label: "Curriculums",
  //     value: "curriculums",
  //     isActive: true,
  //   },
  //   {
  //     label: "Category",
  //     value: "category",
  //     isActive: true,
  //   },
  //   {
  //     label: "Legends",
  //     value: "skill",
  //     isActive: true,
  //   }
  // ];

  // const switchTabBtnClick = (btnName) => {
  //   let { button_name, index } = btnName
  //   setTab(button_name)
  //   setActiveIndex(index)
  //   // redirectSwitchTab({ switchTabArray: btnNameArray.filter(io => io.isActive === true), value: btnName.button_name, setActiveIndex, switchTab: setTab })
  // };
  useEffect(() => {
    if (crud_rights && crud_rights.length > 0) {
      const settingRight = JSON.parse(crud_rights)[parentId][moduleId]
      // console.log("settingRight", settingRight)
      if (settingRight && settingRight.length > 0) {
        setOptionRights(settingRight)
      }
    }
    // optionRights ["0","4"]
  }, [crud_rights])
  console.log(optionRights)
  const headers = {
    'Authentication_key': authentication_key, // Include any authorization token if needed
    'Authorization': 'Bearer ' + authentication_key,
    // Add other headers as needed
  };

  const handleChangeStatus = (id) => {
    axios.postForm(`${baseUrl}/partner/curriculum-change-status`, {
      id,
      auth_bearer_token: authentication_key
    }, {
      headers: {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
        'authentication_key': authentication_key, // Include any authorization token if needed
        'Authorization': 'Bearer ' + authentication_key,
        // Add other headers as needed
      }
    }).then(res => {
      const data = res.data
      if (data.status === "success") {
        setFlag(!flag)
        toast.success(data.message)

      } else {
        toast.error(data.message)
      }
    }
    ).catch(err => {
      console.log(err)
    })
  }

  const handleGetTableData = () => {
    setLoad(true)
    const payload = {
      page: currentPage,
      perPage: rowsPerPage,
      auth_bearer_token: authentication_key
    }
    axios.get(`${baseUrl}/partner/curriculum-table-data`, {
      headers,
      params: payload
    }).then(res => {
      const response = res.data
      if (response.status === "success") {
        const data = response.data
        setTableData(data)
        setTotalPage(response.total_pages)
        setLoad(false)
      } else {
        console.log(response.message)
        setLoad(false)
      }
    }
    ).catch(err => {
      console.log(err)
      setLoad(false)
    })

  }
  const handleDelete = (id) => {
    axios.postForm(`${baseUrl}/partner/curriculum-destroy`, {
      id,
      auth_bearer_token: authentication_key
    }, {
      headers: {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
        'authentication_key': authentication_key, // Include any authorization token if needed
        'Authorization': 'Bearer ' + authentication_key,
        // Add other headers as needed
      }
    }).then(res => {
      const data = res.data
      if (data.status === "success") {
        setFlag(!flag)
        toast.success(data.message)

      } else {
        toast.error(data.message)
      }
    }
    ).catch(err => {
      console.log(err)
    })
  }
  const handleDeleteData = (row) => {
    confirmAlert({
      title: "Confirm delete",
      message: 'Are you sure to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleDelete(row.id)
          },
        },
        {
          label: 'No',
          onClick: () => {
            // Handle the action when 'No' is clicked
            // console.log('You clicked No!');
          },
        },
      ],
      // Custom styles for the confirm pop-up
      overlayClassName: 'custom-overlay-class',
      className: 'custom-modal-class',
    });
  }

  const columns = [
    {
      name: "Curriculum",
      cell: (row) => (<div style={{ cursor: "pointer" }} onClick={() => navigate("/modules/curriculum/view", { state: { id: row.id, isNotAside: true } })}>{row.name ? row.name : "-"}</div>)
    },
    {
      name: "No. of curriculums",
      center: true,
      cell: (row) => (
        <div>
          {row.total_curriculum ? row.total_curriculum : "--"}
        </div>
      )
    },
    {
      name: "Created At",
      center: true,
      cell: (row) => (<div onClick={() => navigate("/modules/curriculum/view", { state: { id: row.id, isNotAside: true } })}>{moment(row.created_at * 1000).format("Do MMM, YYYY")}</div>)
    },
    {
      name: "Option",
      center: true,
      cell: row => {

        const tableMenuType = [
          {
            value: "optionDropdown",
            isActive: false
          },
          {
            value: "optionIcon",
            isActive: true
          }
        ]

        const tableOptionArray = [
          {
            name: "View",
            value: "view",
            icon: <Eye
              size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
              className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
            />,
            className: "",
            isActive: true
          }
          // {
          //   name: "Edit",
          //   value: "edit",
          //   icon: <FaEdit
          //     size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
          //     className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
          //   />,
          //   className: "",
          //   isActive: true
          // },

          // {
          //   name: "Delete",
          //   value: "delete",
          //   icon: <Trash2
          //     size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
          //     className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
          //   />,
          //   className: "",
          //   isActive: true
          // },
          // {
          //   name: `Mask as ${row.status === "1" ? "Unpublish" : "Publish"}`,
          //   value: "markAs",
          //   icon: <CheckSquare
          //     size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
          //     className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
          //   />,
          //   className: "",
          //   isActive: true
          // }
        ]

        const handleTableOption = (e) => {
          if (e.value === "delete") {
            handleDeleteData(row)
          } else if (e.value === "view") {
            navigate("/modules/curriculum/list", { state: { id: row.id, isNotAside: false } })
          } else if (e.value === "edit") {
            navigate("/modules/curriculum/addcurriculum", { state: { id: row.id, isNotAside: true } })
          } else if (e.value === "markAs") {
            handleChangeStatus(row.id)
          }
        }

        return (<>
          {/* <div className="ml-2 view-table-option-tour"> */}
          <TableOptions
            tableOptionArray={tableOptionArray.filter(io => io.isActive === true)}
            handleTableOption={handleTableOption}
            optionType={tableMenuType.filter(io => io.isActive)[0].value}
          />
          {/* </div> */}

        </>)
      }
    }
  ]
  const handlePerPage = (e) => {
    setRowsPerPage(Number(e.target.value))
  }
  const handlePagination = (page) => {
    setCurrentPage(page.selected)
  }
  useEffect(() => {
    handleGetTableData()
  }, [currentPage, rowsPerPage, flag])
  return (<>
    <div className='mb-3'>
      {
        curriculamState === 'Curriculam' ? <Card  >
          <div className='d-flex justify-content-between align-items-center p-3 flex-wrap'>

            <div className='d-flex justify-content-between align-items-center gap' >
              {/* <div className='d-flex justify-content-center align-items-center left-arrow-image'>
                <img src={leftArrow} />
              </div> */}
              <div className='page-heading'>Curriculum</div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap'>
              {/* <ButtonComponent buttonType={'secondary'} title={'Take A Tour'} /> */}
              {tab === "curriculums" && (
                <ButtonComponent
                  buttonType="primary"
                  title="Add New"
                  disabled={handleMangeCrudRight({ userType, options: optionRights, option_id: "2" }) ? false : true}
                  onClick={() => navigate('/modules/curriculum/addcurriculum', { state: { isNotAside: true } })}
                />
              )}

              {tab === "category" ? (
                <ButtonComponent
                  buttonType={"primary"}
                  title="Add Category"
                  onClick={(e) => setModalIsOpen(true)}
                />
              ) : (
                <></>
              )}
            </div>

          </div>
        </Card> :
          <NewCurriculam />
      }
    </div>
    {/* <div className='new_tab'>
      <Tabs
        id="curriculum-skill-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 gap-3"
      >
        <Tab eventKey="curriculum" title="Curriculum">
          <Card className='overflow-auto curriculum-list'>
            {load ? (<>
              <SpinnerComponent />
            </>) : <Datatable
              columns={columns}
              currentPage={currentPage}
              tableData={tableData}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              isSearchable={false}
              paginate={true}
              handlePerPage={handlePerPage}
              handlePagination={handlePagination}
            />}
          </Card>
        </Tab>
        <Tab eventKey="skill" title="Skill">
          <Tabs
              id="skill_remark_tab"
              activeKey={skillKey}
              onSelect={(k) => setSkillKey(k)}
              className="mb-3 gap-3"
            >
              <Tab eventKey="skills" title="Skills">
                  <Skill/>
              </Tab>
              <Tab eventKey="remarks" title="Remarks">
                  <RemarksTable/>
              </Tab>
            </Tabs>
          </Tab>
      </Tabs>
    </div>   */}
    {/* <SwitchTabBtn
      numberOfButton={btnNameArray.filter((io) => io.isActive === true)}
      switchTabBtnClick={switchTabBtnClick}
      mediaQuery={"415px"}
      isActive={true}
      activeIndex={activeIndex}
    /> */}
    <div className="mt-3">
      {
        tab === "curriculums" ? (<>

          <Card className={`overflow-auto curriculum-list ${dataShowType === "grid" ? "no-shadow p-2 mt-2" : "p-4"}`}>
            {/* <div className="d-flex justify-content-end">
              <div className={`d-flex gap-3 grid-list-container align-items-center ${dataShowType === "list" ? "no-shadow" : ""}`}>
                <div>
                  <List
                    onClick={() => setDataShowType("list")}
                    color={dataShowType === "list" ? "#0258a1" : "#C4C4C4"}
                  />
                </div>
                <div className="pl-1">
                  <Grid
                    onClick={() => setDataShowType("grid")}
                    color={dataShowType === "grid" ? "#0258a1" : "#C4C4C4"}
                  />
                </div>
              </div>
            </div> */}
            {dataShowType === "grid" ? (<>
              <div className='curriculum-card-container'>
                {tableData.map((io) => (
                  <div className="curriculum-card">
                    <div className="card-content">
                      <div className="icon">
                        <img src={FolderIcon} alt="Folder Icon" />
                      </div>
                      <div className="details">
                        <h3>{io.name}</h3>
                        <p>{io.total_curriculum} Curriculums | {moment(io.created_at * 1000).format('Do MMM, YYYY')}</p>
                      </div>
                    </div>
                    <button
                      className="view-button"
                      onClick={() => {
                        if (!handleMangeCrudRight({ userType, options: optionRights, option_id: "1" })) {
                          toast.warning("You don't have permission to view this!");
                        } else {
                          navigate("/modules/curriculum/list", { state: { id: io.id, isNotAside: false } });
                        }
                      }}>
                      View
                    </button>
                  </div>
                ))}
              </div>

            </>) :
              (<>
                {load ? (<>
                  <SpinnerComponent />
                </>) : (handleMangeCrudRight({ userType, options: optionRights, option_id: "0" }) ? (<>
                  <Datatable
                    columns={columns}
                    currentPage={currentPage}
                    tableData={tableData}
                    totalPage={totalPage}
                    rowsPerPage={rowsPerPage}
                    isSearchable={false}
                    paginate={true}
                    handlePerPage={handlePerPage}
                    handlePagination={handlePagination}
                    dataShowType={dataShowType}
                    setDataShowType={setDataShowType}
                    noHeader={true}
                  /></>) : (<>
                    <CardBody>
                      <div className="mt-5 pt-5 mb-5 pb-5 d-flex justify-content-center flex-column" style={{ margin: 'auto' }}>
                        <Row>
                          <Col md="12" className="mt-1 d-flex justify-content-center">
                            <h2 className="text-center">You don't have permission to view this!</h2>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </>))}
              </>)}

          </Card>
        </>) : tab === "skill" ? (<>
          <Skill />
        </>) : tab === "category" && (<>
          <Category isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </>)
      }
    </div>
  </>)
}

export default Curriculam