import React from 'react';
import { Input } from "reactstrap";
import "./style.css";

const CustomInputComponent = ({
    type,
    className,
    id,
    onChange,
    disabled,
    invalid,
    readOnly,
    style,
    required,
    name,
    key,
    label,
    checked,
    innerRef,
    inline,
    ariaLabel,
    onClick,
    defaultChecked,
    placeholder,
    value
}) => {
    return (
        <div className="custom-input-wrapper">
            {/* Conditionally render label if it's provided */}
            <Input
                type={type}
                key={key}
                value={value}
                placeholder={placeholder}
                className={`${className}`}
                id={`${id}`}
                onClick={onClick}
                onChange={onChange}
                readOnly={readOnly}
                disabled={disabled}
                invalid={invalid}
                innerRef={innerRef}
                style={style}
                required={required}
                name={name}
                inline={inline}
                checked={checked}
                aria-label={ariaLabel}
                defaultChecked={defaultChecked}
                />
                {label && <label htmlFor={id}>{label}</label>}
        </div>
    );
};

CustomInputComponent.defaultProps = {
    type: "text",
    className: "",
    id: "",
    readOnly: false,
    disabled: false,
    invalid: false,
    required: false,
    name: ""
};

export default CustomInputComponent;
