import React, { useEffect, useState } from 'react'
import { Card, Row, Col, CardBody } from 'react-bootstrap'
import axios from 'axios'
import { InfinitySpin } from "react-loader-spinner";
import moment from "moment";
import { X } from 'react-feather'
import loadable from "react-loadable";
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import "./DemoRequest.css"
import DemoRequestSVG from "../Images/DemoRequest.svg"
import ButtonComponent from '../../../../../@core/components/button/ButtonComponent';
import NoDataTableComponent from '../../../../../@core/components/NoDataTableComponent/NoDataTableComponent';
const SpinnerComponent = () => {
  return (
      <div className="spinner-component">
          <InfinitySpin
              visible={true}
              width="200"
              color="#0258a1"
              ariaLabel="infinity-spin-loading"
          />
      </div>
  );
};
const ApprovedPopUp = loadable({
  loader: () => import(/* webpackChunkName: 'Demo' */ "../DemoRequest/ApprovePopUp/index"),
  loading: () => <></>,
});
const DemoRequest = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
    const authentication_key = localStorage.getItem("authentication_key");
    const [load, setLoad] = useState(false);
    const [flag, setFlag] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalPage, setTotalPage] = useState(1);
    const [demoRequestData, setDemoRequestData] = useState([]);
    const [openApproveModal, setOpenAproveModal] = useState(false);
    const [editRow, setEditRow] = useState("");

    const headers = {
        Authentication_key: authentication_key,
        'Authorization': 'Bearer ' + authentication_key
    };
    const handleGetTableData = () => {
      setLoad(true);
      const payload = {
          page: currentPage,
          perPage: rowsPerPage,
          auth_bearer_token: authentication_key,
      };
      axios
          .get(`${baseUrl}/partner/curriculum-dashboard`, {
              headers,
              params: payload,
          })
          .then((res) => {
              const response = res.data;
              if (response.status === "success") {
                  const data = response.data;
                  setDemoRequestData(data.demo_request);
                  setLoad(false);
              } else {
                  console.log(response.message);
                  setLoad(false);
              }
          })
          .catch((err) => {
              console.log(err);
              setLoad(false);
          });
  };
  const handleRejected = (id) => {
    axios
      .postForm(
        `${baseUrl}/partner/curriculum-demo-status-update`,
        {
          id,
          status: "2",
          auth_bearer_token: authentication_key,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set the content type based on your API requirements
            authentication_key: authentication_key, // Include any authorization token if needed
            'Authorization': 'Bearer ' + authentication_key,
            // Add other headers as needed
          },
        }
      )
      .then((res) => {
        const data = res.data;
        if (data.status === "success") {
          setFlag(!flag);
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleApproved = (id) => {
    axios
      .postForm(
        `${baseUrl}/partner/curriculum-demo-status-update`,
        {
          id,
          status : "1",
          auth_bearer_token: authentication_key,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set the content type based on your API requirements
            authentication_key: authentication_key, // Include any authorization token if needed
            'Authorization': 'Bearer ' + authentication_key,
            // Add other headers as needed
          },
        }
      )
      .then((res) => {
        const data = res.data;
        if (data.status === "success") {
          setFlag(!flag);
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleReject = (row) => {
    confirmAlert({
      title: "Confirm reject",
      message: "Are you sure to reject?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleRejected(row.id);
          },
        },
        {
          label: "No",
          onClick: () => {
            // Handle the action when 'No' is clicked
            // console.log('You clicked No!');
          },
        },
      ],
      // Custom styles for the confirm pop-up
      overlayClassName: "custom-overlay-class",
      className: "custom-modal-class",
    });
  };
  useEffect(() => {
    handleGetTableData()
  }, [rowsPerPage, currentPage, flag, openApproveModal])
  return (
    <div>
      {demoRequestData.length > 0 ? (<Card className='w-100 h-10 p-4 mb-4'>
      <div className='d-flex justify-content-between flex-wrap school-request-section'>
                <span>Demo Requests</span>
            </div>
            {
                load ? (<>
                    <div className='d-flex justify-content-center align-items-center'>
                        <SpinnerComponent />
                    </div>
                </>) : (<>
                    <div className='mt-4 d-flex flex-column '>
                        {
                            demoRequestData && demoRequestData.map((demo, idx) => {
                                return (
                                    <div className='d-flex justify-content-between  flex-wrap   align-items-center'>
                                        <div className='d-flex align-items-center gap  mb-4' >
                                            {demo.icon && <div>
                                                <img src={demo.icon} />
                                            </div>}
                                            <div className='school-name'>
                                                <h2>{demo.name}</h2>
                                                {/* <p style={{ color: '#838383' }}>Curriculum:<span style={{ color: "#000000" }}> {demo.curriculum_name ? demo.curriculum_name : "--"} </span></p> */}
                                                 </div>
                                        </div>
                                        <div className='d-flex gap approved-container-or-reject align-items-center  mb-4'>
                                            <button
                                               onClick={() => {
                                                // Check if the status is not "Approved"
                                                if (demo.status !== "1") {
                                                    setEditRow(demo);
                                                    setOpenAproveModal(true);
                                                }
                                            }}
                                            disabled={demo.status !== "1" ? false : true}
                                                className={`btn ${demo.status === "2"
                                                    ? "home-status-rejected-color"
                                                    : demo.status === "3"
                                                        ? "home-status-pending-color"
                                                        : demo.status === "1"
                                                            ? "home-status-assign-color"
                                                            : "home-status-pending-color"
                                                    }`}
                                            >
                                                {demo.status === "2" ? "Rejected" : demo.status === "3" ? "Pending" : "Approved"}
                                            </button>
                                            {/* <ButtonComponent buttonType={"primary"} title={"Approve"} /> */}
                                            {/* <X
                                                onClick={() => {
                                                    demo.status === "3" && handleRejected(demo.id);
                                                }}
                                                size={20}
                                                color="#C4C4C4"
                                                strokeWidth={3.5}
                                            /> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>)
            }

            {openApproveModal && <ApprovedPopUp 
            curriculumData={demoRequestData} 
            setCurriculumData={setDemoRequestData}
            isOpen={openApproveModal} 
            setIsOpen={setOpenAproveModal}
            handleApprove={handleApproved}
            handleReject={handleReject}
            />}
      </Card>) :  <NoDataTableComponent
            heading="Demo Requests"
            image="demoRequests_svg" // Pass the key to select the correct SVG
            titleText="No Requests Yet?"
            subTitleText="Add Your Curriculum to Start Receiving School Requests!"
            buttonTitle="Add Curriculum"
            // onButtonClick={() => alert('Add Exam clicked!')}
        />}
    </div>
  )
}

export default DemoRequest