
import Backdrop from '@mui/material/Backdrop';
// import Modal from '@mui/material/Modal';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { useEffect, useState } from 'react'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { CardBody } from 'react-bootstrap';
import { FaRegCheckCircle } from 'react-icons/fa';
import VimeoPlayer from '../../../../../@core/components/VimeoPlayer';
import { RxReload } from 'react-icons/rx';
import file from "../../../../../images/addcuriculamfile.svg"
import leftArrow from "../../../../../images/leftArrow.svg"
import "../NewCurriculam.css"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 800,
  // height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ViewFilesTopic = ({ open, setOpen, fileData }) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [docs, setDocs] = useState("")
  useEffect(() => {
    if (fileData && fileData.file_path) {
      setDocs([{
        uri: fileData.file_path,
        fileType: fileData.file_path.split(".")[fileData.file_path.split(".").length - 1],
        fileName: ""
      }])
    }
  }, [fileData])
  function isValidVimeoUrl(url) {
    // Regular expression to match Vimeo video URLs
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/(video\/)?([0-9a-z]+)/;
    return vimeoRegex.test(url);
  }
  return (
    <div>
      <Modal isOpen={open} wrapClassName ="container d-flex align-items-center justify-content-center" contentClassName="custom-modal-style" >
        
        {fileData && <div className='doc-viewer-component'>
          <div onClick={() => handleClose()} style={{ cursor: "pointer", padding:"10px" }} >
        <img src={leftArrow} />
      </div>
          {fileData.file_type && fileData.file_type === "vimeo" ? (<>
            {
              isValidVimeoUrl(fileData.file_path) ? (<>
                <VimeoPlayer videoId={fileData.file_path} />
              </>) : (<>
                <div className='h-100 flex-center'>Only Vimeo Link is supported</div>
              </>)
            }

          </>) : (<>
            {fileData.file_path && docs && docs.length > 0 && <DocViewer config={{
              header: {
                disableHeader: false,
                disableFileName: false,
                retainURLParams: false
              }
            }} documents={docs} pluginRenderers={DocViewerRenderers} />}
          </>)}

        </div>}
      </Modal>
    </div>
  )
}

export default ViewFilesTopic