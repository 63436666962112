import React, { useEffect, useState } from 'react'
import { Card, Row, Col, CardBody } from 'react-bootstrap'
import schoolLogo from '../../../../../images/schoolLogo.svg'
import "./topschool.css"
import axios from 'axios'
import TopSchools from "../Images/TopSchools.svg"
import ButtonComponent from '../../../../../@core/components/button/ButtonComponent'
import NoDataTableComponent from '../../../../../@core/components/NoDataTableComponent/NoDataTableComponent'

const TopSchool = () => {
    const [load, setLoad] = useState(false)
  const baseUrl = process.env.REACT_APP_API_URL;
  const authentication_key = localStorage.getItem("authentication_key");
  const headers = {
    'Authentication_key': authentication_key, // Include any authorization token if needed
    'Authorization': 'Bearer ' + authentication_key,
    // Add other headers as needed
  };

const [schoolLogos, setSchoolLogos] = useState([])
console.log(schoolLogos)
  const handleTopSchoolData = () => {
    setLoad(true)
    axios.get(`${baseUrl}/partner/curriculum-dashboard-top-school`, {
      headers,
    }).then(res => {
      const response = res.data
      if (response.status === "success") {
        const data = response.data
        setSchoolLogos(data)
      } else {
        console.log(response.message)
        setLoad(false)
      }
    }
    ).catch(err => {
      console.log(err)
      setLoad(false)
    })

  }
  useEffect(() => {
    handleTopSchoolData()
  }, [])
    // const schoolLogos = [
    //     {}, {}, {}, {}
    // ]

    return (<>
        {schoolLogos.length > 0 ? (<Card className='p-4'>
            <div className='d-flex align-items-center gap top-school-section' style={{ color: "#838383" }}>
                <p style={{width:"15%"}}>Top Schools</p>
                <div className='d-flex'>
                    {
                        schoolLogos.map((element, idx) => {
                            return (
                                <div style={{ width: "50px", height: "50px" }} className='mx-2' key={idx}>
                                    <img className='w-100' src={element.logo} alt={`School Logo ${idx + 1}`} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </Card>) :  <NoDataTableComponent
            heading="Top Schools"
            image="topSchools_svg" // Pass the key to select the correct SVG
            titleText="No Top Schools Yet!"
            subTitleText="You currently have no requests from school."
            buttonTitle="Add Curriculum"
            // onButtonClick={() => alert('Add Exam clicked!')}
        />}
        </>)

}
export default TopSchool