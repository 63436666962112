import React from 'react'
import { Card, CardBody, CardText } from 'react-bootstrap'
import cross from "../../../../images/+.svg"
import { PlayCircle } from 'react-feather'
import { FaRegFilePdf } from "react-icons/fa6";
import "./index.css"
import SelectedButton from '../../SelectedButton';
import { X } from 'react-feather';
const poster = {
    pdf: "https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/ba/6c/cb/ba6ccb2a-1c27-817b-27d7-3a57fe12d7b1/ReleaseAppIcon-0-0-1x_U007emarketing-0-7-0-85-220.png/1200x600wa.png",
    vimeo: "https://www.svgrepo.com/show/160699/vimeo-logo-in-a-square.svg",
    default: "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-folder-icon-png-image_855010.jpg"
}
const CardWithImage = ({ data, title, type, btnType, onRemove, download, onClickDemo, onDownload, viewOnly = false, onClick }) => {
    const handleSliceTitle = (text) => {
        if (text && text.length > 80) {
            return `${text.slice(0, 80)}...`;
        }
        return text || "";
    }
    return (<>
        <div className='content-card-container' onClick={onClick}>
                {!viewOnly && <div className='cross-icon-container flex-center' onClick={onRemove}>
                    <X size={20} color='rgba(131, 131, 131, 1)' strokeWidth={4} />
                </div>}
            <CardBody className='d-flex align-items-center position-relative d-flex align-items-center justify-content-between'>
                {/* <div className='data-type-icon'>
                    {type === "pdf" ? <PlayCircle /> : <FaRegFilePdf />}
                </div> */}
                <div className='px-2'>{title ? handleSliceTitle(title) : ""}</div>
            </CardBody>
            <div className='img-container position-relative d-flex gap' >
                {!viewOnly && <div className='check-btn' style={{border:"1px solid var(--primary)", borderRadius:"18px"}}>
                    <SelectedButton
                        onClick={onClickDemo}
                        buttonType={btnType === "no" ? "" : "primary"}
                    />
                </div>}
                {!viewOnly && <div className='check-btn' style={{border:"1px solid var(--primary)", borderRadius:"18px"}}>
                    <SelectedButton
                        onClick={onDownload}
                        buttonType={download === "no" ? "" : "primary"}
                        title="Download"
                    />
                </div>}
                {/* <img
                    className='w-100'
                    alt="Sample"
                    src={data.file_type === "pdf" ? poster.pdf : data.file_type === "vimeo" ? poster.vimeo : data.file_path}
                    style={{ height: '100%' }}
                /> */}
            </div>
        </div>

    </>)
}

export default CardWithImage