import { createContext, useState, useEffect } from "react";
import axios from "axios";

// Create the context
export const UserContext = createContext();

// Provider component
export const UserProvider = ({ children }) => {
  const baseUrl = process.env.REACT_APP_API_URL
  const [getUserData, setUserData] = useState([]);
  const [userDataFlag, setUserDataFlag] = useState(false)
  const fetchUserData = () => {
    const authentication_key = localStorage.getItem('authentication_key');
    axios.get(`${baseUrl}/get-provider-user-data`, {
        headers: {
          Authorization: `Bearer ${authentication_key}`,
        },
        params: {
          // Add any parameters like page, perPage if needed
        },
      })
      .then((res) => {
        const response = res.data;
        if (response.status === "success") {
          setUserData(response.data); // Assuming response.data holds the user data
        } else {
          console.log(response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUserData(); // Fetch user data on component mount
  }, [userDataFlag]);

  return (
    <UserContext.Provider value={{ getUserData, setUserData, userDataFlag, setUserDataFlag }}>
      {children}
    </UserContext.Provider>
  );
};
